import React, { useEffect, useRef, useState, useMemo } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./header.css";

export const Header = ({ data }) => {
  const canvasRef = useRef(null);
  const carouselRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Memoize card content
  const cards = useMemo(() => [
    {
      title: "Innovation",
      description: "Pioneering new solutions to meet evolving business challenges",
      color: "#0066cc",
      image: "/img/inovation.jpg"
    },
    {
      title: "Excellence",
      description: "Delivering superior quality in every project we undertake",
      color: "#2ecc71",
      image: "/img/exell.png"
    },
    {
      title: "Solutions",
      description: "Custom-tailored approaches to solve your complex problems",
      color: "#3498db",
      image: "/img/Solutions.jpg"
    },
    {
      title: "Technology",
      description: "Leveraging cutting-edge tools to drive digital transformation",
      color: "#9b59b6",
      image: "/img/Technology.png"
    },
    {
      title: "Future",
      description: "Preparing your business for tomorrow's opportunities",
      color: "#1abc9c",
      image: "/img/Future.png"
    }
  ], []);

  // Check for mobile devices and handle resize
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Canvas and particle animation setup
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      mirror: false,
      offset: 50
    });

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    let animationFrameId;

    const setCanvasSize = () => {
      const dpr = window.devicePixelRatio || 1;
      canvas.width = window.innerWidth * dpr;
      canvas.height = window.innerHeight * dpr;
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
      ctx.scale(dpr, dpr);
    };

    setCanvasSize();

    // Particle system with better performance
    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 2 + 1;
        this.speedX = Math.random() * 1 - 0.5;
        this.speedY = Math.random() * 1 - 0.5;
        this.color = `rgba(66, 133, 244, ${Math.random() * 0.5 + 0.2})`;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x > window.innerWidth || this.x < 0) this.speedX *= -1;
        if (this.y > window.innerHeight || this.y < 0) this.speedY *= -1;
      }

      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    // Create optimized number of particles based on device
    const particleCount = isMobile ? 60 : 120;
    const particles = Array.from({ length: particleCount }, () => new Particle());

    // Optimized connection drawing
    const connectParticles = () => {
      const maxDistance = isMobile ? 80 : 100;

      for (let i = 0; i < particles.length; i++) {
        for (let j = i; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < maxDistance) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(66, 133, 244, ${0.2 * (1 - distance/maxDistance)})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
    };

    // Performance-optimized animation loop
    let lastTime = 0;
    const fps = isMobile ? 30 : 60;
    const fpsInterval = 1000 / fps;

    const animate = (timestamp) => {
      animationFrameId = requestAnimationFrame(animate);
      const elapsed = timestamp - lastTime;

      if (elapsed > fpsInterval) {
        lastTime = timestamp - (elapsed % fpsInterval);
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

        particles.forEach(particle => {
          particle.update();
          particle.draw();
        });

        connectParticles();
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    // Handle window resize
    const handleResize = () => {
      setCanvasSize();
      AOS.refresh();
    };

    window.addEventListener("resize", handleResize);

    // Handle scroll effect
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isMobile]);

  // Carousel rotation and auto-rotation
  useEffect(() => {
    const setupCarousel = () => {
      const carousel = carouselRef.current;
      if (!carousel) return;

      const cardElements = carousel.querySelectorAll('.carousel-card');
      if (cardElements.length === 0) return;

      const totalCards = cardElements.length;
      const radius = isMobile ? 150 : window.innerWidth < 1024 ? 200 : 240;
      const angleStep = (2 * Math.PI) / totalCards;

      cardElements.forEach((card, index) => {
        const angle = angleStep * index - (angleStep * activeCardIndex);
        const x = Math.sin(angle) * radius;
        const z = Math.cos(angle) * radius;
        const scale = 0.8 + (0.2 * (z + radius) / (2 * radius));

        card.style.transform = `translateX(${x}px) translateZ(${z}px) rotateY(${-angle * (180 / Math.PI)}deg) scale(${scale})`;
        card.style.zIndex = Math.round(scale * 100);

        // Visibility logic
        card.style.opacity = z < -radius * 0.3 ? 0 : 0.5 + (0.5 * (z + radius) / (2 * radius));
      });
    };

    setupCarousel();

    // Auto-rotate
    const interval = setInterval(() => {
      setActiveCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [activeCardIndex, cards.length, isMobile]);

  const handleGetStarted = () => {
    const featuresSection = document.getElementById("features");
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCardClick = (index) => {
    setActiveCardIndex(index);
  };

  return (
      <header className={`header-hero ${isScrolled ? 'scrolled' : ''}`}>
        <canvas className="header-canvas" ref={canvasRef} aria-hidden="true"></canvas>
        <div className="header-overlay"></div>

        <div className="header-content">
          <div className="header-brand" data-aos="fade-down" data-aos-delay="100">
            <span className="header-brand-text">TERRY</span>
            <span className="header-brand-highlight">TECHNOLOGIES</span>
          </div>

          <h1 className="header-title" data-aos="fade-up" data-aos-delay="200">
            Pioneering Digital <span className="dynamic-subtitle">
            {cards[activeCardIndex]?.title || "Innovation"}
          </span>
          </h1>

          <p className="header-description" data-aos="fade-up" data-aos-delay="300">
            {data?.description ||
                "Empowering businesses through cutting-edge IT solutions. Our expert team delivers transformative digital experiences, driving your success in the digital age."}
          </p>

          {/* Circular Carousel */}
          <div className="circular-carousel-container" ref={carouselRef} data-aos="zoom-in" data-aos-delay="400">
            {cards.map((card, index) => (
                <div
                    key={index}
                    className={`carousel-card ${index === activeCardIndex ? 'active' : ''}`}
                    style={{
                      '--card-color': card.color,
                      transition: 'transform 0.8s ease-out, opacity 0.8s ease-out'
                    }}
                    onClick={() => handleCardClick(index)}
                >
                  <div className="card-image-container">
                    <img
                        src={card.image}
                        alt={card.title}
                        className="card-image"
                        onError={(e) => {
                          e.target.src = "/api/placeholder/280/180";
                        }}
                    />
                    <div className="card-overlay" style={{ background: `linear-gradient(135deg, ${card.color}99 0%, transparent 70%)` }}></div>
                  </div>
                  <div className="card-content">
                    <h3 className="card-title">{card.title}</h3>
                    <p className="card-description">{card.description}</p>
                  </div>
                </div>
            ))}
          </div>

          {/* Mobile navigation dots */}
          {isMobile && (
              <div className="carousel-nav-dots">
                {cards.map((_, index) => (
                    <button
                        key={index}
                        className={`nav-dot ${index === activeCardIndex ? 'active' : ''}`}
                        onClick={() => handleCardClick(index)}
                        aria-label={`View ${cards[index].title}`}
                    />
                ))}
              </div>
          )}

          <div className="header-cta-container" data-aos="fade-up" data-aos-delay="500">
            <button
                className="header-cta-primary"
                onClick={handleGetStarted}
                aria-label="Learn more about our services"
            >
              <span>More</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="cta-icon">
                <path d="M7 13l5 5 5-5M7 6l5 5 5-5"/>
              </svg>
            </button>
            <button
                className="header-cta-secondary"
                aria-label="Contact our team"
            >
              <span>Contact Us</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="cta-icon">
                <path d="M5 12h14M12 5l7 7-7 7"/>
              </svg>
            </button>
          </div>
        </div>
      </header>
  );
};

export default Header;