import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Header } from "./components/Home/header";
import { Features } from "./components/feature/features";
import { Home1 } from "./components/home1/home1";
import { Services } from "./components/services/services";
import { ServiceDetail } from "./components/services/ServiceDetail";
import { Testimonials } from "./components/testimonials/testimonials";
import { SpecializedTech } from "./components/SpecializedTech/SpecializedTech";
import { Contact } from "./components/contact/contact";
import { WhoWeAre } from "./components/whoweare/whoweare";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";
import ProductShowcase from "./components/ProductShowcase/TerryProductShowcase";
import Certifications from "./components/certifications/certifications";

const AppRoutes = ({ landingPageData }) => {
    const location = useLocation(); // Get the current location

    return (
        <TransitionGroup>
            <CSSTransition key={location.key} timeout={300} classNames="fade">
                <Routes location={location}>
                    {/* Route to the WhoWeAre page */}
                    <Route path="/whoweare" element={<WhoWeAre data={landingPageData.WhoWeAre} />} />

                    {/* Route for the main page with all other content */}
                    <Route
                        path="/"
                        element={
                            <>
                                <Header data={landingPageData.Header} />
                                <Features data={landingPageData.Features} />
                                <Home1 data={landingPageData.Home1} />
                                <ProductShowcase />
                                <SpecializedTech />
                                <Testimonials data={landingPageData.Testimonials} />
                                <WhatsAppButton />
                            </>
                        }
                    />
                    <Route path="/contact" element={<Contact data={landingPageData.Contact} />} />
                    <Route path="/certifications" element={<Certifications />} />
                    <Route path="/services" element={<Services data={landingPageData.Services} />} />
                    <Route path="/service/:serviceId" element={<ServiceDetail data={landingPageData.Services} />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default AppRoutes;