// Loader.jsx
import React, { useState, useEffect, useRef, useMemo } from 'react';
import './Loader.css';

// Highly optimized Particle class with performance as top priority
class Particle {
  constructor(canvas) {
    this.canvas = canvas;
    this.reset();
  }

  reset() {
    this.x = Math.random() * this.canvas.width;
    this.y = Math.random() * this.canvas.height;
    this.size = Math.random() * 2 + 0.5; // Even smaller particles for better performance
    this.speedX = (Math.random() - 0.5) * 1.2; // Reduced speed
    this.speedY = (Math.random() - 0.5) * 1.2;
    this.opacity = Math.random() * 0.4 + 0.1; // Reduced opacity for better rendering
    this.color = this.getRandomColor();
  }

  getRandomColor() {
    // Pre-defined colors for faster processing
    const colors = [
      'rgba(255, 255, 255, VAL)',  // White
      'rgba(99, 102, 241, VAL)'    // Indigo - reduced color options
    ];
    const selectedColor = colors[Math.floor(Math.random() * colors.length)];
    return selectedColor.replace('VAL', this.opacity.toString());
  }

  update() {
    this.x += this.speedX;
    this.y += this.speedY;

    // Simple boundary check with reflection
    if (this.x < 0 || this.x > this.canvas.width) {
      this.speedX *= -1;
    }
    if (this.y < 0 || this.y > this.canvas.height) {
      this.speedY *= -1;
    }

    // Extremely rare reset to avoid clumping
    if (Math.random() < 0.0005) {
      this.reset();
    }
  }

  draw(ctx) {
    // Simplified drawing with no path creation
    ctx.fillStyle = this.color;
    ctx.fillRect(this.x, this.y, this.size, this.size); // Using rects instead of circles for performance
  }
}

export const Loader = ({
                         initialProgress = 0,
                         finalProgress = 100,
                         duration = 2000,
                         color = '#6366f1',
                         showParticles = true,
                         size = 'medium',
                         theme = 'dark',
                         message = 'Loading',
                         completionMessage = 'Complete!'
                       }) => {
  const [progress, setProgress] = useState(initialProgress);
  const [isComplete, setIsComplete] = useState(false);
  const particlesRef = useRef(null);
  const animationFrameRef = useRef(null);
  const particlesArray = useRef([]);
  const lastUpdateTime = useRef(0);

  // Memoize size values to prevent recalculation
  const sizeValues = useMemo(() => {
    const sizeMap = {
      small: { radius: 70, strokeWidth: 6, fontSize: { number: '2rem', percent: '0.9rem' } },
      medium: { radius: 90, strokeWidth: 8, fontSize: { number: '3rem', percent: '1.2rem' } },
      large: { radius: 120, strokeWidth: 10, fontSize: { number: '4rem', percent: '1.5rem' } }
    };
    return sizeMap[size] || sizeMap.medium;
  }, [size]);

  const radius = sizeValues.radius;
  const circumference = 2 * Math.PI * radius;
  const viewBoxSize = (radius + sizeValues.strokeWidth) * 2 + 20;

  // RAF throttling for progress updates
  useEffect(() => {
    const startTime = Date.now();
    const targetFPS = 30; // Limit updates to 30 FPS for progress
    const frameTime = 1000 / targetFPS;

    // Simplified easing function
    const easeOut = t => 1 - (1 - t) * (1 - t); // Quadratic ease out is faster to compute

    const animate = () => {
      const now = Date.now();
      const elapsed = now - startTime;

      // Only update if enough time has passed (throttling)
      if (now - lastUpdateTime.current >= frameTime) {
        lastUpdateTime.current = now;

        const rawProgress = Math.min(elapsed / duration, 1);
        const easedProgress = easeOut(rawProgress);
        const nextProgress = Math.min(
            initialProgress + ((finalProgress - initialProgress) * easedProgress),
            finalProgress
        );

        setProgress(Math.round(nextProgress));

        if (nextProgress >= finalProgress) {
          setIsComplete(true);
          cancelAnimationFrame(animationFrameRef.current);
          return;
        }
      }

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [initialProgress, finalProgress, duration]);

  // Optimized particle system
  useEffect(() => {
    if (!showParticles || !particlesRef.current) return;

    const canvas = particlesRef.current;
    const ctx = canvas.getContext('2d', { alpha: false }); // Disable alpha for better performance

    // Dynamic particle count based on device performance and screen size
    const getParticleCount = () => {
      // Detect low-end devices
      const isLowEndDevice = navigator.hardwareConcurrency <= 4;
      const width = window.innerWidth;

      if (isLowEndDevice) return 15;
      if (width < 480) return 20;
      if (width < 768) return 25;
      return 35; // Reduced from 60 for better performance
    };

    const resizeCanvas = () => {
      // Optimize canvas size - reduce resolution on mobile
      const dpr = window.innerWidth < 768 ? 1 : (window.devicePixelRatio || 1);
      const displayWidth = canvas.clientWidth;
      const displayHeight = canvas.clientHeight;

      // Check if resize is really needed to avoid unnecessary operations
      if (canvas.width !== displayWidth * dpr || canvas.height !== displayHeight * dpr) {
        canvas.width = displayWidth * dpr;
        canvas.height = displayHeight * dpr;
        ctx.scale(dpr, dpr);
      }
    };

    const handleResize = () => {
      resizeCanvas();

      // Reinitialize particles after resize with appropriate count
      const newCount = getParticleCount();
      if (particlesArray.current.length !== newCount) {
        particlesArray.current = Array.from({ length: newCount }, () => new Particle(canvas));
      } else {
        // Just reset existing particles
        particlesArray.current.forEach(p => p.reset());
      }
    };

    // Initial setup
    resizeCanvas();
    particlesArray.current = Array.from(
        { length: getParticleCount() },
        () => new Particle(canvas)
    );

    window.addEventListener('resize', handleResize);

    // Performance optimization: use a fixed frame rate for particles
    const targetFPS = 24; // Lower FPS for particles
    const frameInterval = 1000 / targetFPS;
    let lastFrameTime = 0;
    let animationId;

    const animate = (timestamp) => {
      if (!lastFrameTime) lastFrameTime = timestamp;
      const elapsed = timestamp - lastFrameTime;

      if (elapsed > frameInterval) {
        lastFrameTime = timestamp - (elapsed % frameInterval);

        // Use a simplified clearing method - just paint over with a semi-transparent background
        ctx.fillStyle = theme === 'dark'
            ? 'rgba(15, 23, 42, 0.2)'
            : 'rgba(248, 250, 252, 0.2)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Batch update and draw operations
        particlesArray.current.forEach(particle => {
          particle.update();
          particle.draw(ctx);
        });
      }

      animationId = requestAnimationFrame(animate);
    };

    animationId = requestAnimationFrame(animate);

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationId);
    };
  }, [showParticles, theme]);

  // Pre-calculate stroke offset
  const strokeOffset = circumference - (progress / 100) * circumference;
  const themeClass = theme === 'light' ? 'theme-light' : 'theme-dark';

  return (
      <div className={`modern-loader ${themeClass}`}>
        {showParticles && <canvas ref={particlesRef} className="particles-canvas" />}

        <div className="loader-content">
          <svg
              className="progress-ring"
              width={viewBoxSize}
              height={viewBoxSize}
              viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
          >
            {/* Simplified glow filter */}
            <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur stdDeviation="1.5" result="blur" />
              <feComposite in="SourceGraphic" in2="blur" operator="over" />
            </filter>

            {/* Track circle */}
            <circle
                className="progress-ring__circle-bg"
                strokeWidth={sizeValues.strokeWidth}
                fill="transparent"
                r={radius}
                cx={viewBoxSize / 2}
                cy={viewBoxSize / 2}
            />

            {/* Progress circle */}
            <circle
                className="progress-ring__circle"
                stroke={color}
                strokeWidth={sizeValues.strokeWidth}
                fill="transparent"
                r={radius}
                cx={viewBoxSize / 2}
                cy={viewBoxSize / 2}
                filter="url(#glow)"
                style={{
                  strokeDasharray: circumference,
                  strokeDashoffset: strokeOffset,
                }}
            />
          </svg>

          <div className="progress-content">
            <div className="progress-number" style={{
              fontSize: sizeValues.fontSize.number
            }}>
              <span className="number">{progress}</span>
              <span className="percent" style={{
                fontSize: sizeValues.fontSize.percent
              }}>%</span>
            </div>

            <div className="progress-message">
              {!isComplete ? message : (
                  <div className="completion-message">{completionMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};