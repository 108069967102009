import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './navigation.css'; // Import the CSS file

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleLinkClick = () => setIsOpen(false);
  const handleImageLoad = () => setImageLoaded(true);

  // Preload the logo image
  useEffect(() => {
    const preloadLink = document.createElement('link');
    preloadLink.href = '/img/logo/logo.png';
    preloadLink.rel = 'preload';
    preloadLink.as = 'image';
    document.head.appendChild(preloadLink);

    // Cleanup
    return () => {
      if (document.head.contains(preloadLink)) {
        document.head.removeChild(preloadLink);
      }
    };
  }, []);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Use passive listener for better performance
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
      <nav className={`navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={handleLinkClick}>
            <picture>
              <source srcSet="/img/logo/logo.webp" type="image/webp" />
              <img
                  src="/img/logo/logo.png"
                  alt="Logo"
                  width="150"
                  height="50"
                  fetchpriority="high"
                  onLoad={handleImageLoad}
                  className={imageLoaded ? 'loaded' : ''}
                  style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.3s' }}
              />
            </picture>
          </Link>

          <button
              className={`navbar-toggle ${isOpen ? 'open' : ''}`}
              onClick={handleToggle}
              aria-label="Toggle navigation"
          >
            <span className="navbar-toggle-icon"></span>
          </button>

          <div className={`navbar-menu ${isOpen ? 'open' : ''}`}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to="/" exact activeClassName="active" onClick={handleLinkClick}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/services" activeClassName="active" onClick={handleLinkClick}>
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/whoweare" activeClassName="active" onClick={handleLinkClick}>
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/certifications" activeClassName="active" onClick={handleLinkClick}>
                  Certifications
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" activeClassName="active" onClick={handleLinkClick}>
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
};

export default Navigation;