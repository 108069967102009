import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Server,
    CloudLightning,
    Smartphone,
    ChevronLeft,
    ChevronRight,
    ArrowRight,
    X,
    Check,
    Lock,
    Rocket,
    Blocks,
    FileSpreadsheet,
    CreditCard,
    Workflow,
    Shield,
    TrendingUp,
    Network,
    Globe,
    BarChart3,
    Users,
    Send
} from 'lucide-react';
import './TerryProductShowcase.css';

const iconMap = {
    // Loan Engine Icons
    "Multi-Role Workflow": Workflow,
    "Automated Credit Decisioning": CreditCard,
    "Real-time Tracking": TrendingUp,
    "Compliance Integration": Shield,
    "Custom Approval Workflows": Network,

    // Core Banking System Icons
    "Client Management": Users,
    "Loan Management": FileSpreadsheet,
    "Savings Management": Send,
    "Collateral Management": Lock,
    "Loan Portfolio Tracking": BarChart3,

    // Digital Commodity Exchange Icons
    "Commodity Marketplace": Globe,
    "Secure Payment & Escrow System": Shield,
    "Price Trends & Market Insights": TrendingUp,
    "Transparency & Fair Pricing": FileSpreadsheet,
    "Increased Market Reach": Network,
    "Enhanced Decision-Making": BarChart3
};

const WaveBackground = ({ position }) => (
    <svg
        className={`terry-wave-svg terry-wave-${position}`}
        viewBox="0 0 1440 320"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            fill="currentColor"
            d="M0,160L48,176C96,192,192,224,288,224C384,224,480,192,576,170.7C672,149,768,139,864,149.3C960,160,1056,192,1152,197.3C1248,203,1344,181,1392,170.7L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        />
    </svg>
);

const ProductModal = ({ products, currentProductIndex, onClose, onChangeProduct }) => {
    const product = products[currentProductIndex];

    const getFirstColor = (gradient) => gradient.match(/#[\da-fA-F]{6}/)?.[0] || '#2563eb';

    return (
        <motion.div
            className="terry-product-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            role="dialog"
            aria-modal="true"
            aria-labelledby={`modal-title-${product.name.replace(/\s+/g, '-').toLowerCase()}`}
        >
            <motion.div
                className="terry-product-modal-container"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
            >
                <div
                    className="terry-product-modal-content"
                    style={{
                        background: product.backgroundColor,
                        borderTop: `5px solid ${getFirstColor(product.accentColor)}`
                    }}
                >
                    <div className="terry-modal-header">
                        {React.cloneElement(product.modalIcon, {'aria-hidden': 'true'})}
                        <h2
                            id={`modal-title-${product.name.replace(/\s+/g, '-').toLowerCase()}`}
                            style={{ color: getFirstColor(product.accentColor) }}
                        >
                            {product.name}
                        </h2>
                        <button
                            className="terry-modal-close-btn"
                            onClick={onClose}
                            aria-label="Close modal"
                        >
                            <X aria-hidden="true" />
                        </button>
                    </div>

                    <div className="terry-modal-body">
                        <p className="terry-modal-description">{product.description}</p>

                        <div className="terry-modal-features">
                            <h3 style={{ color: getFirstColor(product.accentColor) }}>
                                Key Features
                            </h3>
                            <div className="terry-features-grid">
                                {product.features?.map((feature, index) => {
                                    const FeatureIcon = iconMap[feature] || Network;
                                    return (
                                        <motion.div
                                            key={index}
                                            className="terry-feature-card"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: index * 0.1 }}
                                        >
                                            <div
                                                className="terry-feature-icon-wrapper"
                                                style={{
                                                    backgroundColor: `${getFirstColor(product.accentColor)}20`,
                                                    color: getFirstColor(product.accentColor)
                                                }}
                                                aria-hidden="true"
                                            >
                                                <FeatureIcon />
                                            </div>
                                            <span>{feature}</span>
                                        </motion.div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="terry-modal-additional-info">
                            <div className="terry-info-section">
                                <h4 style={{ color: getFirstColor(product.accentColor) }}>
                                    Benefits
                                </h4>
                                <ul>
                                    {product.benefits?.map((benefit, index) => (
                                        <li key={index}>
                                            <span aria-hidden="true">
                                                <Check
                                                    className="terry-benefit-check"
                                                    color={getFirstColor(product.accentColor)}
                                                />
                                            </span>
                                            {benefit}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="terry-modal-product-list">
                            <h4>Other Products</h4>
                            <div className="terry-modal-product-navigation" role="tablist">
                                {products.map((prod, index) => (
                                    <motion.div
                                        key={index}
                                        className={`terry-modal-product-item ${index === currentProductIndex ? 'terry-active' : ''}`}
                                        onClick={() => onChangeProduct(index)}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        role="tab"
                                        id={`product-tab-${index}`}
                                        aria-selected={index === currentProductIndex}
                                        tabIndex={index === currentProductIndex ? 0 : -1}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                e.preventDefault();
                                                onChangeProduct(index);
                                            }
                                        }}
                                    >
                                        {React.cloneElement(prod.icon, {'aria-hidden': 'true'})}
                                        <span>{prod.name}</span>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="terry-modal-navigation">
                        <button
                            className="terry-nav-btn terry-previous"
                            onClick={() => onChangeProduct((currentProductIndex - 1 + products.length) % products.length)}
                            aria-label="Previous product"
                        >
                            <ChevronLeft aria-hidden="true" />
                        </button>
                        <button
                            className="terry-nav-btn terry-next"
                            onClick={() => onChangeProduct((currentProductIndex + 1) % products.length)}
                            aria-label="Next product"
                        >
                            <ChevronRight aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

const TerryProductShowcase = () => {
    const [currentProductIndex, setCurrentProductIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProductIndex, setModalProductIndex] = useState(null);
    const [autoplay, setAutoplay] = useState(true);

    const products = [
        {
            name: "Terry Loan Engine",
            description: "A comprehensive workflow management platform for streamlining loan applications across multiple organizational roles with intelligent decisioning.",
            icon: <Server className="terry-product-icon terry-blue" />,
            modalIcon: <Lock className="terry-modal-icon" />,
            accentColor: "linear-gradient(135deg, #3b82f6, #2563eb)",
            backgroundColor: "linear-gradient(135deg, #bae6fd, #ddd6fe)",
            features: [
                "Multi-Role Workflow",
                "Automated Credit Decisioning",
                "Real-time Tracking",
                "Compliance Integration",
                "Custom Approval Workflows"
            ],
            benefits: [
                "Streamline complex loan processes across different organizational roles",
                "Improve credit decision accuracy through automated assessment",
                "Enable real-time visibility into loan application status",
                "Ensure regulatory compliance with integrated checks",
                "Flexibility to create custom approval workflows"
            ]
        },
        {
            name: "Terry Core Banking System",
            description: "A flexible and scalable Core Banking System optimized for rapid deployment, with a user-centric interface for financial institutions.",
            icon: <CloudLightning className="terry-product-icon terry-purple" />,
            modalIcon: <Rocket className="terry-modal-icon" />,
            accentColor: "linear-gradient(135deg, #8b5cf6, #7c3aed)",
            backgroundColor: "linear-gradient(135deg, #e9d5ff, #fae8ff)",
            features: [
                "Client Management",
                "Loan Management",
                "Savings Management",
                "Collateral Management",
                "Loan Portfolio Tracking"
            ],
            benefits: [
                "Centralize and streamline client information management",
                "Simplify and automate loan processing and tracking",
                "Manage savings accounts with comprehensive tools",
                "Efficiently track and manage collateral assets",
                "Provide comprehensive insights into loan portfolio performance"
            ]
        },
        {
            name: "Digital Agricultural Commodity Exchange",
            description: "A Digital Agriculture Commodity Exchange (DACE) is an online platform that connects farmers, traders, buyers, and logistics providers for the seamless trading of agricultural commodities.",
            icon: <Smartphone className="terry-product-icon terry-green" />,
            modalIcon: <Blocks className="terry-modal-icon" />,
            accentColor: "linear-gradient(135deg, #10b981, #059669)",
            backgroundColor: "linear-gradient(135deg, #d1fae5, #a7f3d0)",
            features: [
                "Commodity Marketplace",
                "Secure Payment & Escrow System",
                "Price Trends & Market Insights"
            ],
            benefits: [
                "Transparency & Fair Pricing",
                "Increased Market Reach",
                "Enhanced Decision-Making"
            ]
        }
    ];

    const handleOpenModal = (index) => {
        setModalProductIndex(index);
        setIsModalOpen(true);
        setAutoplay(false); // Pause autoplay when modal is open
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalProductIndex(null);
        setAutoplay(true); // Resume autoplay when modal is closed
    };

    const handleNextProduct = () => {
        setCurrentProductIndex((prev) => (prev + 1) % products.length);
    };

    const handlePreviousProduct = () => {
        setCurrentProductIndex((prev) => (prev === 0 ? products.length - 1 : prev - 1));
    };

    const handleModalProductChange = (index) => {
        setModalProductIndex(index);
    };

    useEffect(() => {
        let slideInterval;

        if (autoplay) {
            slideInterval = setInterval(() => {
                setCurrentProductIndex((prev) => (prev + 1) % products.length);
            }, 5000);
        }

        return () => clearInterval(slideInterval);
    }, [products.length, autoplay]);

    const currentProduct = products[currentProductIndex];

    return (
        <div className="terry-showcase-wrapper">
            <WaveBackground position="top" />
            <main id="main-content" className="terry-showcase-container">
                <div className="terry-showcase-content">
                    <h1 className="terry-main-heading">
                        Our Solutions
                    </h1>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentProductIndex}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.5, type: "tween" }}
                            className="terry-showcase-grid"
                        >
                            <div className="terry-product-details">
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                    className="terry-product-header"
                                >
                                    {React.cloneElement(currentProduct.icon, {'aria-hidden': 'true'})}
                                    <h2 className="terry-product-title">{currentProduct.name}</h2>
                                </motion.div>

                                <motion.p
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.3 }}
                                    className="terry-product-description"
                                >
                                    {currentProduct.description}
                                </motion.p>

                                <div className="terry-product-info-grid">
                                    <motion.div
                                        initial={{ opacity: 0, x: -30 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <h3 className="terry-info-section-title">Key Features</h3>
                                        <ul className="terry-feature-list">
                                            {currentProduct.features.map((feature, index) => {
                                                const FeatureIcon = iconMap[feature] || Network;
                                                return (
                                                    <motion.li
                                                        key={index}
                                                        initial={{ opacity: 0, x: -10 }}
                                                        animate={{ opacity: 1, x: 0 }}
                                                        transition={{ delay: 0.5 + index * 0.1 }}
                                                        className="terry-feature-item"
                                                    >
                                                        <FeatureIcon className="terry-feature-icon" aria-hidden="true" />
                                                        <span>{feature}</span>
                                                    </motion.li>
                                                );
                                            })}
                                        </ul>
                                    </motion.div>
                                </div>

                                <motion.button
                                    onClick={() => handleOpenModal(currentProductIndex)}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="terry-learn-more-button"
                                    style={{ background: currentProduct.accentColor }}
                                >
                                    <span>Learn More</span>
                                    <ArrowRight className="terry-button-icon" aria-hidden="true" />
                                </motion.button>

                                <div className="terry-product-dots" role="tablist" aria-label="Product navigation">
                                    {products.map((product, index) => (
                                        <button
                                            key={index}
                                            className={`terry-dot-button ${index === currentProductIndex ? 'terry-active' : ''}`}
                                            onClick={() => setCurrentProductIndex(index)}
                                            aria-label={`View ${product.name}`}
                                            aria-selected={index === currentProductIndex}
                                            role="tab"
                                            tabIndex={index === currentProductIndex ? 0 : -1}
                                            style={{
                                                backgroundColor: index === currentProductIndex
                                                    ? currentProduct.accentColor.match(/#[\da-fA-F]{6}/)?.[0]
                                                    : 'rgba(255, 255, 255, 0.3)'
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>

                            <motion.div
                                initial={{ opacity: 0, scale: 0.95 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ delay: 0.5 }}
                                className="terry-product-preview-section"
                            >
                                <div
                                    className="terry-product-preview-card"
                                    style={{ background: currentProduct.backgroundColor }}
                                >
                                    <div
                                        className="terry-preview-gradient"
                                        style={{
                                            background: currentProduct.accentColor,
                                            animation: 'terry-gradientFlow 10s ease infinite'
                                        }}
                                        aria-hidden="true"
                                    />
                                    <div className="terry-preview-content">
                                        <div className="terry-preview-header">
                                            {React.cloneElement(currentProduct.modalIcon, {'aria-hidden': 'true'})}
                                            <div className="terry-navigation-buttons">
                                                <motion.button
                                                    onClick={handlePreviousProduct}
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="terry-nav-button"
                                                    aria-label="Previous product"
                                                >
                                                    <ChevronLeft aria-hidden="true" />
                                                </motion.button>
                                                <motion.button
                                                    onClick={handleNextProduct}
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="terry-nav-button"
                                                    aria-label="Next product"
                                                >
                                                    <ChevronRight aria-hidden="true" />
                                                </motion.button>
                                            </div>
                                        </div>
                                        <h3 className="terry-preview-title">Product Overview</h3>
                                        <p className="terry-preview-description">
                                            Cutting-edge solutions transforming business landscapes.
                                        </p>
                                        <div className="terry-preview-stats">
                                            <div className="terry-preview-stat">
                                                <h4>Scalability</h4>
                                                <p>Enterprise-grade</p>
                                            </div>
                                            <div className="terry-preview-stat">
                                                <h4>Security</h4>
                                                <p>Advanced protocols</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </main>
            <WaveBackground position="bottom" />

            <AnimatePresence>
                {isModalOpen && modalProductIndex !== null && (
                    <ProductModal
                        products={products}
                        currentProductIndex={modalProductIndex}
                        onClose={handleCloseModal}
                        onChangeProduct={handleModalProductChange}
                    />
                )}
            </AnimatePresence>
        </div>
    );
};

export default TerryProductShowcase;