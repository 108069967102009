import React, { useState, useEffect, useRef, useMemo } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import "./certifications.css";

export const Certifications = () => {
    const [activeCategory, setActiveCategory] = useState("iso");
    const [searchTerm, setSearchTerm] = useState("");
    const [isScrolled, setIsScrolled] = useState(false);
    const [selectedCert, setSelectedCert] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const certContainerRef = useRef(null);
    const modalRef = useRef(null);

    const categories = [
        { id: "iso", name: "ISO Standards" },
        { id: "industry", name: "Industry Specific" },
        { id: "compliance", name: "Compliance" },
        { id: "health", name: "Health & Safety" },
        { id: "all", name: "All Certifications" }
    ];

    const certifications = [
        {
            id: "iso9001",
            name: "ISO 9001",
            category: "iso",
            description: "Quality Management Systems",
            icon: "🏆",
            details: "ISO 9001 is the international standard for Quality Management Systems (QMS). It provides a framework for organizations to ensure they can consistently deliver products and services that meet customer and regulatory requirements.",
            benefits: [
                "Enhanced customer satisfaction through consistent quality",
                "Improved organizational efficiency and cost reduction",
                "Better risk management and decision-making processes",
                "Increased market opportunities and competitive advantage",
                "Continuous improvement culture across the organization"
            ],
            process: [
                { step: 1, title: "Gap Analysis", description: "Assess your current practices against ISO 9001 requirements", icon: "📊" },
                { step: 2, title: "Implementation", description: "Develop and implement processes to meet requirements", icon: "⚙️" },
                { step: 3, title: "Documentation", description: "Create required policies, procedures and records", icon: "📝" },
                { step: 4, title: "Internal Audit", description: "Verify compliance with ISO 9001 requirements", icon: "🔍" },
                { step: 5, title: "Certification Audit", description: "External assessment by certification body", icon: "🏅" }
            ]
        },
        {
            id: "iso14001",
            name: "ISO 14001",
            category: "iso",
            description: "Environmental Management Systems",
            icon: "🌱",
            details: "ISO 14001 is the international standard for Environmental Management Systems (EMS). It helps organizations minimize their environmental impact and comply with applicable laws and regulations.",
            benefits: [
                "Reduced environmental footprint and resource consumption",
                "Compliance with environmental legislation",
                "Enhanced corporate image and stakeholder confidence",
                "Cost savings through energy efficiency and waste reduction",
                "Improved risk management of environmental challenges"
            ],
            process: [
                { step: 1, title: "Environmental Review", description: "Assess environmental aspects and impacts", icon: "🌍" },
                { step: 2, title: "Planning", description: "Develop environmental objectives and management programs", icon: "📈" },
                { step: 3, title: "Implementation", description: "Execute environmental management processes", icon: "🔄" },
                { step: 4, title: "Monitoring", description: "Track and measure environmental performance", icon: "📊" },
                { step: 5, title: "Certification", description: "External verification by accredited body", icon: "✅" }
            ]
        },
        {
            id: "iso45001",
            name: "ISO 45001",
            category: "iso",
            description: "Occupational Health and Safety",
            icon: "🔒",
            details: "ISO 45001 is the international standard for Occupational Health and Safety Management Systems. It provides a framework for organizations to improve employee safety, reduce workplace risks, and create better working conditions.",
            benefits: [
                "Reduced workplace incidents, accidents and disruptions",
                "Lower insurance premiums and reduced absenteeism",
                "Demonstrated compliance with regulations",
                "Improved organizational reputation and stakeholder confidence",
                "Enhanced employee morale and productivity"
            ],
            process: [
                { step: 1, title: "Hazard Identification", description: "Identify workplace hazards and assess risks", icon: "⚠️" },
                { step: 2, title: "OHS Planning", description: "Develop health and safety objectives and processes", icon: "📝" },
                { step: 3, title: "Implementation", description: "Execute OHS management processes", icon: "🛠️" },
                { step: 4, title: "Performance Evaluation", description: "Monitor and measure OHS activities", icon: "📊" },
                { step: 5, title: "Certification Audit", description: "External assessment by certification body", icon: "🏅" }
            ]
        },
        {
            id: "iso22000",
            name: "ISO 22000",
            category: "iso",
            description: "Food Safety Management",
            icon: "🍽️",
            details: "ISO 22000 sets the international standard for Food Safety Management Systems (FSMS), ensuring safe food production, handling, and distribution through hazard controls (HACCP principles).",
            benefits: [
                "Prevents foodborne hazards and contamination",
                "Ensures compliance with global food safety regulations",
                "Builds consumer and regulatory trust",
                "Reduces product recalls and liabilities",
                "Improves supply chain transparency"
            ],
            process: [
                { step: 1, title: "Hazard Analysis", description: "Identify biological, chemical, and physical food risks", icon: "🔬" },
                { step: 2, title: "Prerequisite Programs", description: "Implement hygiene and operational controls", icon: "🧼" },
                { step: 3, title: "HACCP Plan", description: "Develop critical control points and monitoring", icon: "📋" },
                { step: 4, title: "Internal Audit", description: "Verify FSMS effectiveness", icon: "🔍" },
                { step: 5, title: "Certification", description: "External audit by accredited body", icon: "✅" }
            ]
        },
        {
            id: "iso27001",
            name: "ISO 27001",
            category: "iso",
            description: "Information Security Management",
            icon: "🔐",
            details: "ISO 27001 is the global standard for Information Security Management Systems (ISMS), providing a framework to protect sensitive data through risk management and security controls.",
            benefits: [
                "Protects confidential data from breaches",
                "Meets legal and regulatory compliance (e.g., GDPR)",
                "Reduces cybersecurity risks and financial losses",
                "Enhances customer and partner trust",
                "Improves organizational resilience"
            ],
            process: [
                { step: 1, title: "Risk Assessment", description: "Identify information security risks", icon: "⚠️" },
                { step: 2, title: "ISMS Implementation", description: "Apply security controls and policies", icon: "🛡️" },
                { step: 3, title: "Staff Training", description: "Educate employees on security protocols", icon: "👨‍💻" },
                { step: 4, title: "Internal Audit", description: "Evaluate ISMS effectiveness", icon: "📊" },
                { step: 5, title: "Certification Audit", description: "External validation by certification body", icon: "🏅" }
            ]
        },
        {
            id: "iso17025",
            name: "ISO 17025",
            category: "iso",
            description: "Testing and Calibration Laboratories",
            icon: "🧪",
            details: "ISO 17025 specifies requirements for the competence of testing and calibration laboratories, ensuring accurate and reliable results.",
            benefits: [
                "Internationally recognized lab competency",
                "Improved accuracy and reliability of test results",
                "Enhanced customer confidence",
                "Compliance with regulatory requirements",
                "Competitive advantage in tenders"
            ],
            process: [
                { step: 1, title: "Gap Analysis", description: "Compare current lab practices with ISO 17025", icon: "📋" },
                { step: 2, title: "System Development", description: "Implement quality and technical procedures", icon: "⚙️" },
                { step: 3, title: "Validation", description: "Verify methods and equipment accuracy", icon: "🔎" },
                { step: 4, title: "Internal Audit", description: "Assess compliance with standard", icon: "📊" },
                { step: 5, title: "Accreditation", description: "External assessment by accreditation body", icon: "✅" }
            ]
        },
        {
            id: "iso13485",
            name: "ISO 13485",
            category: "iso",
            description: "Medical Devices Quality Management",
            icon: "💉",
            details: "ISO 13485 sets the quality management requirements for the design and manufacture of medical devices, ensuring safety and regulatory compliance.",
            benefits: [
                "Ensures compliance with global medical device regulations",
                "Reduces risks in device design and production",
                "Facilitates market access (e.g., EU, FDA)",
                "Improves traceability and risk management",
                "Enhances patient safety"
            ],
            process: [
                { step: 1, title: "Regulatory Review", description: "Identify applicable medical device regulations", icon: "📜" },
                { step: 2, title: "QMS Implementation", description: "Develop device-specific quality processes", icon: "🏥" },
                { step: 3, title: "Risk Management", description: "Apply ISO 14971 for risk analysis", icon: "⚠️" },
                { step: 4, title: "Internal Audit", description: "Verify QMS effectiveness", icon: "🔍" },
                { step: 5, title: "Certification", description: "Audit by notified body", icon: "✅" }
            ]
        },
        {
            id: "iso20000",
            name: "ISO 20000-1",
            category: "iso",
            description: "IT Service Management",
            icon: "💻",
            details: "ISO 20000-1 is the international standard for IT Service Management (ITSM), aligning IT services with business needs through best practices.",
            benefits: [
                "Improved IT service delivery and reliability",
                "Alignment with ITIL best practices",
                "Higher customer satisfaction",
                "Reduced service disruptions and costs",
                "Demonstrated compliance for tenders"
            ],
            process: [
                { step: 1, title: "Service Assessment", description: "Evaluate current IT service processes", icon: "📊" },
                { step: 2, title: "Process Design", description: "Implement ITSM policies and workflows", icon: "🔄" },
                { step: 3, title: "Tool Integration", description: "Adopt ITSM software (e.g., ServiceNow)", icon: "🛠️" },
                { step: 4, title: "Internal Audit", description: "Check compliance with ISO 20000", icon: "🔍" },
                { step: 5, title: "Certification", description: "External audit by certification body", icon: "🏅" }
            ]
        },
        {
            id: "iso22301",
            name: "ISO 22301",
            category: "iso",
            description: "Business Continuity Management",
            icon: "🔄",
            details: "ISO 22301 provides a framework for Business Continuity Management (BCM), ensuring organizations can respond to and recover from disruptions.",
            benefits: [
                "Minimizes downtime during crises",
                "Protects revenue and reputation",
                "Meets legal and customer requirements",
                "Improves risk resilience",
                "Enhances stakeholder confidence"
            ],
            process: [
                { step: 1, title: "Business Impact Analysis", description: "Identify critical functions and risks", icon: "📉" },
                { step: 2, title: "BCM Strategy", description: "Develop recovery plans and resources", icon: "🗂️" },
                { step: 3, title: "Plan Testing", description: "Conduct drills and simulations", icon: "🧪" },
                { step: 4, title: "Internal Audit", description: "Validate BCM effectiveness", icon: "🔍" },
                { step: 5, title: "Certification", description: "External audit for compliance", icon: "✅" }
            ]
        },
        {
            id: "iso50001",
            name: "ISO 50001",
            category: "iso",
            description: "Energy Management Systems",
            icon: "⚡",
            details: "ISO 50001 helps organizations improve energy performance, reduce costs, and lower carbon emissions through systematic energy management.",
            benefits: [
                "Reduces energy consumption and costs",
                "Lowers carbon footprint",
                "Complies with environmental regulations",
                "Improves corporate sustainability image",
                "Identifies energy efficiency opportunities"
            ],
            process: [
                { step: 1, title: "Energy Review", description: "Assess energy use and baseline performance", icon: "📊" },
                { step: 2, title: "EnMS Implementation", description: "Develop energy policies and objectives", icon: "🌿" },
                { step: 3, title: "Monitoring", description: "Track energy metrics and improvements", icon: "📈" },
                { step: 4, title: "Internal Audit", description: "Verify EnMS compliance", icon: "🔍" },
                { step: 5, title: "Certification", description: "External audit by certification body", icon: "🏅" }
            ]
        },
        {
            id: "iso37001",
            name: "ISO 37001",
            category: "iso",
            description: "Anti-Bribery Management Systems",
            icon: "⚖️",
            details: "ISO 37001 provides guidelines for establishing anti-bribery policies, procedures, and controls to prevent, detect, and address bribery.",
            benefits: [
                "Reduces legal and reputational risks",
                "Demonstrates commitment to ethical practices",
                "Meets regulatory requirements (e.g., FCPA, UK Bribery Act)",
                "Enhances trust with stakeholders",
                "Improves due diligence in partnerships"
            ],
            process: [
                { step: 1, title: "Risk Assessment", description: "Identify bribery risks and vulnerabilities", icon: "⚠️" },
                { step: 2, title: "Policy Development", description: "Create anti-bribery policies and controls", icon: "📜" },
                { step: 3, title: "Training", description: "Educate employees and partners on compliance", icon: "👥" },
                { step: 4, title: "Internal Audit", description: "Evaluate ABMS effectiveness", icon: "🔍" },
                { step: 5, title: "Certification", description: "External validation by certification body", icon: "✅" }
            ]
        },
        {
            id: "iso31000",
            name: "ISO 31000",
            category: "iso",
            description: "Risk Management",
            icon: "📊",
            details: "ISO 31000 provides principles and guidelines for effective risk management across all organizational activities.",
            benefits: [
                "Proactive identification and mitigation of risks",
                "Supports decision-making and strategic planning",
                "Enhances operational resilience",
                "Improves stakeholder confidence",
                "Aligns with other ISO standards (e.g., 9001, 14001)"
            ],
            process: [
                { step: 1, title: "Risk Identification", description: "Identify internal and external risks", icon: "🔎" },
                { step: 2, title: "Risk Analysis", description: "Assess likelihood and impact of risks", icon: "📉" },
                { step: 3, title: "Risk Treatment", description: "Develop mitigation strategies", icon: "🛡️" },
                { step: 4, title: "Monitoring", description: "Continuously review risks", icon: "🔄" },
                { step: 5, title: "Integration", description: "Embed risk management into processes", icon: "⚙️" }
            ]
        },
        {
            id: "iso20121",
            name: "ISO 20121",
            category: "iso",
            description: "Sustainable Events Management",
            icon: "🎪",
            details: "ISO 20121 provides a framework for managing events sustainably, minimizing environmental and social impacts.",
            benefits: [
                "Reduces waste and carbon footprint of events",
                "Enhances stakeholder engagement",
                "Improves brand reputation",
                "Meets CSR and sustainability goals",
                "Cost savings through resource efficiency"
            ],
            process: [
                { step: 1, title: "Sustainability Objectives", description: "Define goals for the event", icon: "🎯" },
                { step: 2, title: "Planning", description: "Develop sustainable logistics and procurement", icon: "🗓️" },
                { step: 3, title: "Implementation", description: "Execute event with sustainability controls", icon: "🔄" },
                { step: 4, title: "Evaluation", description: "Measure environmental/social impact", icon: "📊" },
                { step: 5, title: "Certification", description: "Optional external verification", icon: "✅" }
            ]
        },
        {
            id: "iso10002",
            name: "ISO 10002",
            category: "iso",
            description: "Customer Satisfaction",
            icon: "😊",
            details: "ISO 10002 provides guidelines for effective complaint handling to enhance customer satisfaction and loyalty.",
            benefits: [
                "Improves customer retention and trust",
                "Identifies systemic service/product issues",
                "Reduces escalation to regulators",
                "Enhances brand reputation",
                "Supports continuous improvement"
            ],
            process: [
                { step: 1, title: "Complaint Receipt", description: "Establish accessible complaint channels", icon: "📥" },
                { step: 2, title: "Acknowledgment", description: "Confirm receipt to customer", icon: "✉️" },
                { step: 3, title: "Investigation", description: "Analyze root cause of complaint", icon: "🔍" },
                { step: 4, title: "Resolution", description: "Implement corrective actions", icon: "🛠️" },
                { step: 5, title: "Feedback", description: "Close loop with customer", icon: "🔄" }
            ]
        },
        {
            id: "iso41001",
            name: "ISO 41001",
            category: "iso",
            description: "Facility Management Systems",
            icon: "🏢",
            details: "ISO 41001 specifies requirements for effective facility management to optimize space, infrastructure, and services.",
            benefits: [
                "Improves workplace productivity and safety",
                "Reduces operational costs",
                "Enhances asset lifecycle management",
                "Aligns FM with organizational goals",
                "Supports sustainability objectives"
            ],
            process: [
                { step: 1, title: "Needs Assessment", description: "Identify stakeholder requirements", icon: "📋" },
                { step: 2, title: "FMS Design", description: "Develop FM policies and processes", icon: "🏗️" },
                { step: 3, title: "Implementation", description: "Deploy FM systems and tools", icon: "⚙️" },
                { step: 4, title: "Performance Review", description: "Monitor FM KPIs", icon: "📊" },
                { step: 5, title: "Certification", description: "Optional external audit", icon: "✅" }
            ]
        },
        {
            id: "iso29001",
            name: "ISO 29001",
            category: "iso",
            description: "Oil and Gas Quality Management",
            icon: "🛢️",
            details: "ISO 29001 adapts ISO 9001 for the petroleum, petrochemical, and natural gas industries, addressing sector-specific quality requirements.",
            benefits: [
                "Ensures consistent product quality in high-risk environments",
                "Reduces non-conformities in exploration/production",
                "Meets industry-specific customer requirements (e.g., API)",
                "Improves HSE performance",
                "Facilitates global supply chain integration"
            ],
            process: [
                { step: 1, title: "Gap Analysis", description: "Assess current QMS against ISO 29001", icon: "📊" },
                { step: 2, title: "Sector-Specific Controls", description: "Implement oil/gas quality processes", icon: "⚙️" },
                { step: 3, title: "HSE Integration", description: "Align with safety/environmental standards", icon: "🌍" },
                { step: 4, title: "Internal Audit", description: "Verify compliance", icon: "🔍" },
                { step: 5, title: "Certification", description: "External audit by certification body", icon: "🏅" }
            ]
        },

        // Industry Specific
        {
            id: "iatf16949",
            name: "IATF 16949",
            category: "industry",
            description: "Automotive Quality Management",
            icon: "🚗",
            details: "IATF 16949 is an automotive sector-specific quality management standard. It builds on the foundation of ISO 9001 with additional requirements specific to the automotive industry.",
            benefits: [
                "Improved product and process quality for automotive applications",
                "Reduced variation and waste in the supply chain",
                "Enhanced customer satisfaction and brand reputation",
                "Better integration with automotive OEM requirements",
                "Access to global automotive markets and manufacturers"
            ],
            process: [
                { step: 1, title: "Gap Assessment", description: "Evaluate current systems against IATF requirements", icon: "📋" },
                { step: 2, title: "Implementation Plan", description: "Develop roadmap for system development", icon: "🗺️" },
                { step: 3, title: "Process Development", description: "Create and implement automotive-specific processes", icon: "⚙️" },
                { step: 4, title: "Internal System Audit", description: "Verify compliance with IATF requirements", icon: "🔍" },
                { step: 5, title: "Certification Audit", description: "External assessment by authorized body", icon: "🏁" }
            ]
        },
        {
            id: "as9100",
            name: "AS9100",
            category: "industry",
            description: "Aerospace Quality Management",
            icon: "✈️",
            details: "AS9100 is the aerospace industry's QMS standard, extending ISO 9001 with requirements for aviation, space, and defense sectors.",
            benefits: [
                "Meets stringent aerospace safety and reliability standards",
                "Reduces defects in critical components",
                "Enhances supply chain traceability",
                "Required by major aerospace OEMs (e.g., Boeing, Airbus)",
                "Supports compliance with FAA/EASA regulations"
            ],
            process: [
                { step: 1, title: "Gap Analysis", description: "Compare current QMS to AS9100", icon: "📊" },
                { step: 2, title: "Documentation", description: "Develop aerospace-specific procedures", icon: "📝" },
                { step: 3, title: "Implementation", description: "Train staff and deploy processes", icon: "🛠️" },
                { step: 4, title: "Internal Audit", description: "Verify compliance", icon: "🔍" },
                { step: 5, title: "Certification", description: "Audit by accredited body (e.g., NADCAP)", icon: "✅" }
            ]
        },
        {
            id: "tl9000",
            name: "TL 9000",
            category: "industry",
            description: "Telecom Quality Management",
            icon: "📱",
            details: "TL 9000 is a telecommunications-specific QMS standard with metrics for product and service performance in the telecom industry.",
            benefits: [
                "Standardizes quality metrics across telecom supply chains",
                "Reduces costs and inefficiencies in network products",
                "Aligns with global telecom requirements (e.g., QuEST Forum)",
                "Improves customer satisfaction in a high-reliability sector",
                "Provides benchmarking data for continuous improvement"
            ],
            process: [
                { step: 1, title: "Requirements Review", description: "Study TL 9000 handbook metrics", icon: "📚" },
                { step: 2, title: "QMS Adaptation", description: "Integrate telecom-specific KPIs", icon: "📊" },
                { step: 3, title: "Data Collection", description: "Implement measurement systems", icon: "📈" },
                { step: 4, title: "Internal Audit", description: "Validate compliance", icon: "🔍" },
                { step: 5, title: "Registration", description: "Certification by QuEST Forum-recognized body", icon: "🏅" }
            ]
        },
        {
            id: "cemark",
            name: "CE Mark",
            category: "industry",
            description: "European Conformity",
            icon: "🇪🇺",
            details: "CE Marking indicates compliance with EU health, safety, and environmental standards for products sold in the European Economic Area.",
            benefits: [
                "Legal requirement for selling products in the EU",
                "Enhances market access across 30+ countries",
                "Demonstrates product safety to customers",
                "Reduces liability risks",
                "Facilitates customs clearance"
            ],
            process: [
                { step: 1, title: "Directive Identification", description: "Determine applicable EU directives (e.g., Machinery, RoHS)", icon: "📜" },
                { step: 2, title: "Conformity Assessment", description: "Test products against essential requirements", icon: "🔬" },
                { step: 3, title: "Technical Documentation", description: "Prepare technical file and DoC", icon: "🗂️" },
                { step: 4, title: "Affix CE Mark", description: "Apply marking to product/packaging", icon: "✅" },
                { step: 5, title: "Surveillance", description: "Maintain compliance for audits", icon: "👀" }
            ]
        },
        {
            id: "bifma",
            name: "BIFMA",
            category: "industry",
            description: "Furniture Industry Standards",
            icon: "🪑",
            details: "BIFMA standards define safety, durability, and sustainability requirements for office and institutional furniture.",
            benefits: [
                "Ensures furniture meets ergonomic and safety needs",
                "Supports LEED and sustainability certifications",
                "Reduces liability from product failures",
                "Enhances buyer confidence in commercial furniture",
                "Aligns with ANSI/BIFMA testing protocols"
            ],
            process: [
                { step: 1, title: "Standard Selection", description: "Choose relevant BIFMA standard (e.g., X5.1 for chairs)", icon: "📋" },
                { step: 2, title: "Product Testing", description: "Conduct tests at accredited lab", icon: "🧪" },
                { step: 3, title: "Certification", description: "Obtain BIFMA compliance label", icon: "🏷️" },
                { step: 4, title: "Marketing", description: "Promote compliance to buyers", icon: "📢" },
                { step: 5, title: "Surveillance", description: "Periodic retesting for renewal", icon: "🔄" }
            ]
        },
        {
            id: "halal",
            name: "Halal",
            category: "industry",
            description: "Islamic Dietary Compliance",
            icon: "🍖",
            details: "Halal certification ensures products meet Islamic dietary laws, covering ingredients, preparation, and hygiene standards.",
            benefits: [
                "Access to $2 trillion global Halal market",
                "Meets religious requirements for Muslim consumers",
                "Enhances export potential to OIC countries",
                "Improves brand trust among Islamic communities",
                "Often overlaps with hygiene and quality standards"
            ],
            process: [
                { step: 1, title: "Application", description: "Submit to Halal certification body", icon: "📝" },
                { step: 2, title: "Ingredient Review", description: "Verify compliance of all components", icon: "🔍" },
                { step: 3, title: "Audit", description: "Inspect facilities and processes", icon: "🏭" },
                { step: 4, title: "Certification", description: "Issue Halal certificate", icon: "📜" },
                { step: 5, title: "Renewal", description: "Annual audits to maintain status", icon: "🔄" }
            ]
        },

        // Compliance
        {
            id: "rohs",
            name: "RoHS",
            category: "compliance",
            description: "Restriction of Hazardous Substances",
            icon: "⚠️",
            details: "RoHS (Restriction of Hazardous Substances) limits the use of specific hazardous materials (e.g., lead, mercury) in electrical/electronic products.",
            benefits: [
                "Legal requirement for electronics in the EU and other regions",
                "Reduces environmental and health risks from toxic substances",
                "Aligns with circular economy principles",
                "Facilitates CE Marking for electronics",
                "Improves brand reputation for sustainability"
            ],
            process: [
                { step: 1, title: "Material Analysis", description: "Test products for restricted substances", icon: "🔬" },
                { step: 2, title: "Supplier Declarations", description: "Obtain compliance docs from vendors", icon: "📄" },
                { step: 3, title: "Technical Documentation", description: "Prepare RoHS compliance file", icon: "🗂️" },
                { step: 4, title: "CE Mark Integration", description: "Include in EU Declaration of Conformity", icon: "🇪🇺" },
                { step: 5, title: "Surveillance", description: "Monitor supply chain for ongoing compliance", icon: "👀" }
            ]
        },
        {
            id: "gdpr",
            name: "GDPR",
            category: "compliance",
            description: "General Data Protection Regulation",
            icon: "🔒",
            details: "GDPR is the EU's data privacy law governing the collection, processing, and storage of personal data of EU residents.",
            benefits: [
                "Avoids fines (up to 4% of global revenue)",
                "Builds customer trust in data handling",
                "Streamlines data management processes",
                "Enhances cybersecurity measures",
                "Global benchmark for privacy compliance"
            ],
            process: [
                { step: 1, title: "Data Mapping", description: "Identify personal data flows", icon: "🗺️" },
                { step: 2, title: "Gap Analysis", description: "Assess current practices vs GDPR", icon: "📊" },
                { step: 3, title: "Remediation", description: "Implement privacy controls (e.g., DSARs, encryption)", icon: "🛡️" },
                { step: 4, title: "Documentation", description: "Create records of processing activities", icon: "📝" },
                { step: 5, title: "Training", description: "Educate staff on compliance", icon: "👥" }
            ]
        },
        {
            id: "hipaa",
            name: "HIPAA",
            category: "compliance",
            description: "Health Insurance Portability and Accountability",
            icon: "🏥",
            details: "HIPAA sets US standards for protecting sensitive patient health information (PHI) by covered entities and business associates.",
            benefits: [
                "Avoids penalties (up to $1.5M per violation)",
                "Protects patient privacy and trust",
                "Reduces risks of data breaches",
                "Required for healthcare providers and partners",
                "Aligns with other frameworks (e.g., HITRUST)"
            ],
            process: [
                { step: 1, title: "Risk Analysis", description: "Identify PHI vulnerabilities", icon: "⚠️" },
                { step: 2, title: "Policies", description: "Develop HIPAA Privacy/Security Rules", icon: "📜" },
                { step: 3, title: "Safeguards", description: "Implement admin/tech/physical controls", icon: "🔐" },
                { step: 4, title: "BAAs", description: "Sign Business Associate Agreements", icon: "🤝" },
                { step: 5, title: "Training", description: "Staff education on PHI handling", icon: "👩‍⚕️" }
            ]
        },
        {
            id: "soc",
            name: "SOC",
            category: "compliance",
            description: "Service Organization Control",
            icon: "🔍",
            details: "SOC reports (Type 1/2) verify controls at service organizations relevant to security, availability, processing integrity, confidentiality, or privacy.",
            benefits: [
                "Assures customers of secure outsourced services",
                "Competitive differentiator for cloud/SaaS providers",
                "Reduces customer audit requests",
                "Aligns with ISO 27001, GDPR, etc.",
                "Enhances vendor risk management"
            ],
            process: [
                { step: 1, title: "Scope Definition", description: "Determine SOC 1 vs SOC 2 and system boundaries", icon: "📋" },
                { step: 2, title: "Control Design", description: "Map controls to Trust Services Criteria", icon: "🗂️" },
                { step: 3, title: "Testing", description: "Operate controls for audit period (SOC 2)", icon: "🧪" },
                { step: 4, title: "Audit", description: "CPA firm evaluates controls", icon: "📊" },
                { step: 5, title: "Report Issuance", description: "Distribute to stakeholders", icon: "📄" }
            ]
        },
        {
            id: "sa8000",
            name: "SA 8000",
            category: "compliance",
            description: "Social Accountability",
            icon: "👥",
            details: "SA8000 is an auditable standard for ethical workplace conditions, covering child labor, forced labor, health/safety, and fair wages.",
            benefits: [
                "Demonstrates commitment to ethical labor practices",
                "Improves brand reputation and consumer trust",
                "Meets CSR and ESG reporting requirements",
                "Reduces supply chain risks",
                "Attracts ethically conscious investors"
            ],
            process: [
                { step: 1, title: "Self-Assessment", description: "Evaluate current labor practices", icon: "📋" },
                { step: 2, title: "Corrective Actions", description: "Address gaps in social compliance", icon: "🛠️" },
                { step: 3, title: "Training", description: "Educate staff and suppliers", icon: "👩‍🏫" },
                { step: 4, title: "Certification Audit", description: "On-site assessment by SAI-accredited body", icon: "✅" },
                { step: 5, title: "Surveillance", description: "Annual audits to maintain certification", icon: "🔄" }
            ]
        },

        // Health & Safety
        {
            id: "haccp",
            name: "HACCP",
            category: "health",
            description: "Hazard Analysis Critical Control Point",
            icon: "🍲",
            details: "HACCP is a systematic preventive approach to food safety that identifies physical, chemical, and biological hazards.",
            benefits: [
                "Prevents food safety hazards rather than reacts",
                "Required by FDA/USDA and global regulators",
                "Reduces product recalls and liabilities",
                "Integrates with ISO 22000",
                "Builds consumer confidence"
            ],
            process: [
                { step: 1, title: "Hazard Analysis", description: "Identify potential food safety risks", icon: "🔬" },
                { step: 2, title: "CCP Identification", description: "Determine critical control points", icon: "📍" },
                { step: 3, title: "Monitoring", description: "Establish CCP limits and procedures", icon: "📊" },
                { step: 4, title: "Corrective Actions", description: "Define responses to deviations", icon: "🛠️" },
                { step: 5, title: "Verification", description: "Validate HACCP plan effectiveness", icon: "✅" }
            ]
        },
        {
            id: "gmp",
            name: "GMP",
            category: "health",
            description: "Good Manufacturing Practice",
            icon: "🏭",
            details: "GMP ensures products are consistently produced and controlled according to quality standards (e.g., FDA 21 CFR Part 110/211).",
            benefits: [
                "Legal requirement for pharmaceuticals, food, and cosmetics",
                "Reduces contamination and errors",
                "Ensures product identity, strength, and purity",
                "Facilitates FDA/EMA inspections",
                "Foundation for other standards (e.g., ISO 13485)"
            ],
            process: [
                { step: 1, title: "Facility Design", description: "Implement hygiene and layout controls", icon: "🏗️" },
                { step: 2, title: "SOPs", description: "Develop standard operating procedures", icon: "📝" },
                { step: 3, title: "Training", description: "Qualify staff on GMP requirements", icon: "👩‍🔬" },
                { step: 4, title: "Quality Control", description: "Test raw materials and finished products", icon: "🧪" },
                { step: 5, title: "Audits", description: "Internal and regulatory inspections", icon: "🔍" }
            ]
        },
        {
            id: "organic",
            name: "Organic",
            category: "health",
            description: "Organic Production Standards",
            icon: "🌿",
            details: "Organic certification verifies agricultural products are grown/processed without synthetic pesticides, GMOs, or artificial inputs.",
            benefits: [
                "Commands premium pricing (20-30% higher)",
                "Meets growing consumer demand for organic",
                "Supports environmental sustainability",
                "Complies with USDA/EU organic regulations",
                "Differentiates in crowded markets"
            ],
            process: [
                { step: 1, title: "Application", description: "Submit to accredited certifier (e.g., USDA)", icon: "📝" },
                { step: 2, title: "Farm/ Facility Inspection", description: "On-site audit of practices", icon: "🏞️" },
                { step: 3, title: "Documentation Review", description: "Verify inputs and traceability", icon: "🗂️" },
                { step: 4, title: "Certification", description: "Issue organic certificate", icon: "📜" },
                { step: 5, title: "Annual Renewal", description: "Continued compliance audits", icon: "🔄" }
            ]
        }
    ];

    const filteredCertifications = useMemo(() => {
        return certifications.filter(cert =>
            (activeCategory === "all" || cert.category === activeCategory) &&
            (cert.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                cert.description.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [activeCategory, searchTerm]);
    // Initialize canvas animation
    useEffect(() => {
        const canvas = document.querySelector('.certifications-canvas');
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        let width, height;
        let particles = [];

        // Resize handler
        const resize = () => {
            width = canvas.width = window.innerWidth;
            height = canvas.height = window.innerHeight;
            // Recreate particles
            particles = [];
            for (let i = 0; i < width * 0.05; i++) {
                particles.push({
                    x: Math.random() * width,
                    y: Math.random() * height,
                    size: Math.random() * 2 + 0.5,
                    speedX: Math.random() * 0.5 - 0.25,
                    speedY: Math.random() * 0.5 - 0.25,
                    opacity: Math.random() * 0.5 + 0.2
                });
            }
        };

        // Animation loop
        const animate = () => {
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';

            for (let i = 0; i < particles.length; i++) {
                const p = particles[i];
                ctx.globalAlpha = p.opacity;
                ctx.beginPath();
                ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
                ctx.fill();

                // Update position
                p.x += p.speedX;
                p.y += p.speedY;

                // Wrap around edges
                if (p.x < 0) p.x = width;
                if (p.x > width) p.x = 0;
                if (p.y < 0) p.y = height;
                if (p.y > height) p.y = 0;
            }

            // Draw connections
            ctx.globalAlpha = 0.2;
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
            ctx.lineWidth = 0.5;

            for (let i = 0; i < particles.length; i++) {
                for (let j = i + 1; j < particles.length; j++) {
                    const dx = particles[i].x - particles[j].x;
                    const dy = particles[i].y - particles[j].y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < 100) {
                        ctx.globalAlpha = 0.2 * (1 - distance / 100);
                        ctx.beginPath();
                        ctx.moveTo(particles[i].x, particles[i].y);
                        ctx.lineTo(particles[j].x, particles[j].y);
                        ctx.stroke();
                    }
                }
            }

            requestAnimationFrame(animate);
        };

        window.addEventListener('resize', resize);
        resize();
        animate();

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);
    useEffect(() => {
        const canvas = document.querySelector('.certifications-canvas');
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        let width, height;
        let particles = [];

        const resize = () => {
            width = canvas.width = window.innerWidth;
            height = canvas.height = window.innerHeight;
            particles = [];
            for (let i = 0; i < width * 0.05; i++) {
                particles.push({
                    x: Math.random() * width,
                    y: Math.random() * height,
                    size: Math.random() * 2 + 0.5,
                    speedX: Math.random() * 0.5 - 0.25,
                    speedY: Math.random() * 0.5 - 0.25,
                    opacity: Math.random() * 0.5 + 0.2
                });
            }
        };

        const animate = () => {
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
            for (let i = 0; i < particles.length; i++) {
                const p = particles[i];
                ctx.globalAlpha = p.opacity;
                ctx.beginPath();
                ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
                ctx.fill();
                p.x += p.speedX;
                p.y += p.speedY;
                if (p.x < 0) p.x = width;
                if (p.x > width) p.x = 0;
                if (p.y < 0) p.y = height;
                if (p.y > height) p.y = 0;
            }
            ctx.globalAlpha = 0.2;
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
            ctx.lineWidth = 0.5;
            for (let i = 0; i < particles.length; i++) {
                for (let j = i + 1; j < particles.length; j++) {
                    const dx = particles[i].x - particles[j].x;
                    const dy = particles[i].y - particles[j].y;
                    const distance = Math.sqrt(dx * dx + dy * dy);
                    if (distance < 100) {
                        ctx.globalAlpha = 0.2 * (1 - distance / 100);
                        ctx.beginPath();
                        ctx.moveTo(particles[i].x, particles[i].y);
                        ctx.lineTo(particles[j].x, particles[j].y);
                        ctx.stroke();
                    }
                }
            }
            requestAnimationFrame(animate);
        };

        window.addEventListener('resize', resize);
        resize();
        animate();

        return () => window.removeEventListener('resize', resize);
    }, []);
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });

        const handleScroll = () => {
            if (certContainerRef.current) {
                const position = certContainerRef.current.getBoundingClientRect();
                setIsScrolled(position.top < 50);
            }
        };
        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Ensure initial state is set on mount
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Modal outside click and ESC key handling
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        const handleEscKey = (event) => {
            if (isModalOpen && event.key === 'Escape') {
                closeModal();
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleEscKey);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [isModalOpen]);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => document.body.style.overflow = 'auto';
    }, [isModalOpen]);

    const openModal = (cert) => {
        setSelectedCert(cert);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTimeout(() => setSelectedCert(null), 300);
    };

    const getDefaultProcess = () => {
        return [
            { step: 1, title: "Gap Analysis", description: "Assessment of current systems against requirements in Uganda", icon: "📋" },
            { step: 2, title: "Implementation", description: "Development and execution tailored to Ugandan businesses", icon: "⚙️" },
            { step: 3, title: "Documentation", description: "Creation of policies for Uganda compliance", icon: "📝" },
            { step: 4, title: "Audit", description: "Internal verification for Ugandan standards", icon: "🔍" },
            { step: 5, title: "Certification", description: "External assessment by a Uganda-recognized body", icon: "🏅" }
        ];
    };

    return (
        <>
            <Helmet>
                <title>Business Certifications in Uganda - Terry Technologies</title>
                <meta name="description" content="Explore business certifications in Uganda with Terry Technologies, including ISO 9001, ISO 14001, compliance, and health & safety standards for Ugandan businesses." />
                <meta name="keywords" content="certifications in Uganda, ISO standards Uganda, ISO 9001 Uganda, business compliance Uganda, Terry Technologies Uganda" />
                <meta name="geo.region" content="UG" />
                <meta name="geo.placename" content="Kampala, Uganda" />
                <meta name="geo.position" content="0.347596;32.582520" />
                <link rel="canonical" href="https://www.terrytechnologies.com/certifications" />
                <script type="application/ld+json">{`
        {
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": "Business Certifications in Uganda",
            "description": "Terry Technologies offers a range of certifications in Uganda including ISO standards, compliance, and health & safety for businesses.",
            "url": "https://www.terrytechnologies.com/certifications",
            "publisher": {
                "@type": "Organization",
                "name": "Terry Technologies",
                "url": "https://www.terrytechnologies.com",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Kampala",
                    "addressRegion": "Central Region",
                    "addressCountry": "UG"
                }
            },
            "hasPart": [
                ${filteredCertifications ?
                    filteredCertifications.map(cert => `
                    {
                        "@type": "CreativeWork",
                        "name": "${cert.name} Certification in Uganda",
                        "description": "${cert.description} available for businesses in Uganda.",
                        "category": "${categories && categories.find(cat => cat.id === cert.category)?.name || ''}",
                        "availableIn": "Uganda"
                    }
                `).join(',') : ''}
            ]
        }
    `}</script>
            </Helmet>

            <section id="certifications" className="certifications-section">
                <canvas className="certifications-canvas" aria-hidden="true"></canvas>
                <div className="certifications-overlay"></div>

                <div className={`certifications-container ${isScrolled ? 'scrolled' : ''}`} ref={certContainerRef}>
                    <header className="certifications-header">
                        <h1 className="certifications-title">
                            Business <span className="certifications-highlight">Certifications</span> in Uganda
                        </h1>
                        <p className="certifications-description">
                            Elevate your Ugandan business with internationally recognized certifications like ISO 9001, ISO 14001, and more. Terry Technologies offers expert consulting services across Uganda to achieve and maintain these standards.
                        </p>
                    </header>

                    <div className="certifications-search-container">
                        <div className="search-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="search-icon">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <input
                                type="text"
                                placeholder="Search certifications in Uganda..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="search-input"
                            />
                            {searchTerm && (
                                <button
                                    className="clear-search"
                                    onClick={() => setSearchTerm("")}
                                    aria-label="Clear search"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </button>
                            )}
                        </div>
                    </div>

                    <nav className={`categories-tabs ${isScrolled ? 'sticky-tabs' : ''}`}>
                        {categories.map(category => (
                            <button
                                key={category.id}
                                role="tab"
                                aria-selected={activeCategory === category.id}
                                className={`category-tab ${activeCategory === category.id ? 'active' : ''}`}
                                onClick={() => setActiveCategory(category.id)}
                            >
                                {category.name}
                            </button>
                        ))}
                    </nav>

                    <section className="certifications-grid">
                        {filteredCertifications.length > 0 ? (
                            filteredCertifications.map(cert => (
                                <article className="certification-card" key={cert.id} data-category={cert.category} data-aos="fade-up">
                                    <div className="certification-content">
                                        <div className="certification-top">
                                            <div className="certification-icon-container">
                                                <span className="certification-icon">{cert.icon}</span>
                                            </div>
                                            <div className="certification-heading">
                                                <h2 className="certification-name">{cert.name}</h2>
                                                <p className="certification-description">{cert.description}</p>
                                            </div>
                                        </div>
                                        <Link
                                            to={`/certifications/${cert.id}-uganda`}
                                            className="learn-more-btn"
                                            onClick={(e) => { e.preventDefault(); openModal(cert); }}
                                        >
                                            Learn More
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M5 12h14M12 5l7 7-7 7"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </article>
                            ))
                        ) : (
                            <div className="no-results">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                                <p>No certifications found matching your search criteria in Uganda.</p>
                                <button className="reset-search" onClick={() => { setSearchTerm(""); setActiveCategory("all"); }}>
                                    Reset Search
                                </button>
                            </div>
                        )}
                    </section>

                    <footer className="certifications-cta">
                        <h3>Need help with certifications in Uganda?</h3>
                        <p>Our experts in Uganda guide you through every step of the certification process.</p>
                        <Link to="/contact" className="consultation-btn">
                            Request a Consultation in Uganda
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="cta-icon"
                            >
                                <path d="M5 12h14M12 5l7 7-7 7" />
                            </svg>
                        </Link>
                    </footer>
                </div>

                <div className={`certification-modal-overlay ${isModalOpen ? 'active' : ''}`}>
                    {selectedCert && (
                        <div className="certification-modal" ref={modalRef}>
                            <button className="modal-close-btn" onClick={closeModal} aria-label="Close modal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </button>

                            <header className="certification-modal-header">
                                <div className="modal-icon-container">
                                    <span className="modal-icon">{selectedCert.icon}</span>
                                </div>
                                <div>
                                    <h1 className="modal-title">{selectedCert.name} in Uganda</h1>
                                    <p className="modal-subtitle">{selectedCert.description}</p>
                                </div>
                            </header>

                            <div className="modal-content">
                                {selectedCert.details && (
                                    <section className="modal-section">
                                        <h2>Overview</h2>
                                        <p>{selectedCert.details}</p>
                                    </section>
                                )}

                                {selectedCert.benefits && selectedCert.benefits.length > 0 && (
                                    <section className="modal-section">
                                        <h2>Benefits for Ugandan Businesses</h2>
                                        <ul className="benefits-list">
                                            {selectedCert.benefits.map((benefit, index) => (
                                                <li key={index}>{benefit}</li>
                                            ))}
                                        </ul>
                                    </section>
                                )}

                                <section className="modal-section">
                                    <h2>Certification Process in Uganda</h2>
                                    <div className="process-timeline">
                                        {(selectedCert.process || getDefaultProcess()).map((step) => (
                                            <div className="process-step" key={step.step}>
                                                <div className="step-circle">
                                                    {step.icon ? (
                                                        <span className="step-icon">{step.icon}</span>
                                                    ) : (
                                                        <span className="step-number">{step.step}</span>
                                                    )}
                                                </div>
                                                <div className="step-content">
                                                    <h3>{step.title}</h3>
                                                    <p>{step.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>

                            <footer className="modal-footer">
                                <button className="contact-btn">
                                    <Link to="/contact" className="consultation-btn">
                                        Contact Our Uganda Certification Experts
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14M12 5l7 7-7 7"/>
                                        </svg>
                                    </Link>
                                </button>
                            </footer>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default Certifications;